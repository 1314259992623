import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import get from 'lodash.get';
import Testimonials from "./testimonials";
import Layout from "./layout";
import Emoji from "./emoji";

const shortcodes = { Link, Testimonials, Emoji,  } // Provide common components here

export default function Post({ data: { post, allPosts } }) {
  const { edges: posts } = allPosts;
  let featuredImgFluid = get(post, 'frontmatter.featuredImage.childImageSharp.fluid');
  return (
    <Layout blog={true}>
      <div className="post-wrapper">
        <div className="post">
          <div className="post-content">
            {featuredImgFluid && <Img sizes={{...featuredImgFluid, aspectRatio: 4/3}}/>}
            <h1>{post.frontmatter.title}</h1>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
            <div className="post-content-affiliate">
              Refresh is community-supported. When you buy through our links, we may earn an affiliate commission.
            </div>
          </div>
          <div className="post-nav">
            <Link to="/">
              <h3>PC Build Guides</h3>
            </Link>
            <p>New articles weekly!</p>
            <ul>
              {posts && posts.map(({ node: post }) => (
                <li className="post-nav-post" key={post.id}>
                  <Link to={`../../${post.frontmatter.slug}`}>
                    <h4>{post.frontmatter.title}</h4>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    post: mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allPosts: allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`